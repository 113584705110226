
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { GetAxios } from "../../../Shared";
import { config } from "../../../Environments";
import { setGlobalLoading } from "../../../Store";


const ExampleIndex = () => {

  const { t } = useTranslation('resume');
  const [seminarlist, setSeminarList] = useState<any>([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setGlobalLoading(true));
    GetAxios().get(config.apiBase + '/api/Announce/GetListingAnnounces', {
      params: {
        listingNo: 3
      }
    }).then(s => {
      setSeminarList(s.data.data || []);
      dispatch(setGlobalLoading(false));
    });
  }, []);
  return (
    <>
      <div className="container my-3">
        <div className="card">
          <div className="card-body px-lg-4 px-sm-4 px-md-4 pt-4 mx-auto">
            <div className="row">
              <div className="col-md-12">
                <div className="resume-format-text">
                  <div className="text-center">
                    <h2 className="fw-700"> {t('resumeExmpl.examplesWellFormattedResumes')} <br />{t('resumeExmpl.youcanUse')}</h2>
                  </div>
                  <div className="desc">
                    <p className="fw-500 fs-18"> {t('resumeExmpl.resumeFormattingDesc')}.</p>
                    <p className="fw-500 fs-18"> {t('resumeExmpl.sampleResumeFormats')}.</p>
                  </div>
                </div>
              </div>
            <div className="col-md-12">
                <div className="example-resume-sec my-2">
                  <h4 className="fw-700 example-title">1. {t('resumeExmpl.excelsiorTitle')}</h4>


                  <div className="img-sec px-5 pt-2">
                    {seminarlist && seminarlist.length > 0 ? (
                      <div className="row">
                        <div className="col-md-6 col-12 mb-4">
                          <img src={config.apiBase + seminarlist[0]?.banner}
                            onError={(ev: any) => ev.target.src = "/images/content/causerie-img.jpg"} className="announce-example-img" alt="Excelsior Example" />
                        </div>
                        <div className="col-md-6 col-12 mb-4">
                          <img src="/images/resume-img/excelsior-example.jpg" className="announce-example-img" alt="Excelsior Example" />
                         </div>
                      </div>
                    ) : (
                      <div className="row">
                       <div className="col-md-12 col-12 mb-4">
                          <img src="/images/resume-img/excelsior-example.jpg" className="announce-example-img" alt="Excelsior Example" />
                         </div>
                      </div>
                    )}
                  </div>

             
                </div>

                <div className="text-center mt-1">
                  <Link to="/resumes" target='_blank'>
                    <img src="/images/resume-img/build-resume-btn.png" className="cursor-pointer resume-btn-smallj04" alt="Build my resume now" />
                  </Link>
                </div>
                <div className="example-resume-detail">
                  <p className="fw-500 fs-18"> {t('resumeExmpl.excelsiorDesc')}.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="example-resume-sec my-2">
                <h4 className="fw-700 example-title">2. {t('resumeExmpl.firstLookTitle')} </h4>

                <div className="img-sec px-5 pt-2">
                {seminarlist && seminarlist.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6 col-12 mb-4">
                      <img src={config.apiBase + seminarlist[1]?.banner}
                        onError={(ev: any) => ev.target.src = "/images/content/causerie-img.jpg"} className="announce-example-img" alt="Excelsior Example" />
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                      <img src="/images/resume-img/firstlook-example.jpg" className="announce-example-img" alt="First Look Example" />
                    </div>
                  </div>
                  ) : (
                    <div className="row">
                   
                    <div className="col-md-12 col-12 mb-4">
                      <img src="/images/resume-img/firstlook-example.jpg" className="announce-example-img" alt="First Look Example" />
                    </div>
                  </div>)}
                </div>

                <div className="text-center mt-1">
                  <Link to="/resumes" target='_blank'>
                    <img src="/images/resume-img/build-resume-btn.png" className="cursor-pointer resume-btn-smallj04" alt="Build my resume now" />
                  </Link>
                </div>
                <div className="example-resume-detail">
                  <p className="fw-500 fs-18"> {t('resumeExmpl.firstLookDesc')}.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="example-resume-sec my-2">
                <h4 className="fw-700 example-title">3. {t('resumeExmpl.capoisTitle')} </h4>

                <div className="img-sec px-5 pt-2">
                {seminarlist && seminarlist.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6 col-12 mb-4">
                      <img src={config.apiBase + seminarlist[2]?.banner}
                        onError={(ev: any) => ev.target.src = "/images/content/causerie-img.jpg"} className="announce-example-img" alt="Excelsior Example" />
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                      <img src="/images/resume-img/capois-example.jpg" className="announce-example-img" alt="First Look Example" />
                    </div>
                  </div>
                  ) : (
                    <div className="row">
                   
                    <div className="col-md-12 col-12 mb-4">
                      <img src="/images/resume-img/capois-example.jpg" className="announce-example-img" alt="First Look Example" />
                    </div>
                  </div>)}
                </div>

                <div className="text-center mt-1">
                  <Link to="/resumes" target='_blank'>
                    <img src="/images/resume-img/build-resume-btn.png" className="cursor-pointer resume-btn-smallj04" alt="Build my resume now" />
                  </Link>
                </div>
                <div className="example-resume-detail">
                  <p className="fw-500 fs-18"> {t('resumeExmpl.capoisDesc')}.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="example-resume-sec my-2">
                <h4 className="fw-700 example-title">4. {t('resumeExmpl.epicTitle')} </h4>

                <div className="img-sec px-5 pt-2">
                {seminarlist && seminarlist.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6 col-12 mb-4">
                      <img src={config.apiBase + seminarlist[3]?.banner}
                        onError={(ev: any) => ev.target.src = "/images/content/causerie-img.jpg"} className="announce-example-img" alt="Excelsior Example" />
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                      <img src="/images/resume-img/epic-example.jpg" className="announce-example-img" alt="First Look Example" />
                    </div>
                  </div>
                  ) : (
                    <div className="row">
                  
                    <div className="col-md-12 col-12 mb-4">
                      <img src="/images/resume-img/epic-example.jpg" className="announce-example-img" alt="First Look Example" />
                    </div>
                  </div>)}
                </div>

                <div className="text-center mt-1">
                  <Link to="/resumes" target='_blank'>
                    <img src="/images/resume-img/build-resume-btn.png" className="cursor-pointer resume-btn-smallj04" alt="Build my resume now" />
                  </Link>
                </div>
                <div className="example-resume-detail">
                  <p className="fw-500 fs-18"> {t('resumeExmpl.epicDesc')}.</p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="example-resume-sec my-2">
                <h4 className="fw-700 example-title">5.  {t('resumeExmpl.boulevardTitle')} </h4>

                <div className="img-sec px-5 pt-2">
                {seminarlist && seminarlist.length > 0 ? (
                  <div className="row">
                    <div className="col-md-6 col-12 mb-4">
                      <img src={config.apiBase + seminarlist[4]?.banner}
                        onError={(ev: any) => ev.target.src = "/images/content/causerie-img.jpg"} className="announce-example-img" alt="Excelsior Example" />
                    </div>
                    <div className="col-md-6 col-12 mb-4">
                      <img src="/images/resume-img/boulevard-example.jpg" className="announce-example-img" alt="First Look Example" />
                    </div>
                  </div>
                    ) : (
                      <div className="row">
                     
                      <div className="col-md-12 col-12 mb-4">
                        <img src="/images/resume-img/boulevard-example.jpg" className="announce-example-img" alt="First Look Example" />
                      </div>
                    </div>)})
                </div>

                <div className="text-center mt-1">
                  <Link to="/resumes" target='_blank'>
                    <img src="/images/resume-img/build-resume-btn.png" className="cursor-pointer resume-btn-smallj04" alt="Build my resume now" />
                  </Link>
                </div>
                <div className="example-resume-detail">
                  <p className="fw-500 fs-18"> {t('resumeExmpl.boulevardDesc')}!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};
export default ExampleIndex;
