import { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useForm } from 'react-hook-form';
import { FaEnvelope, FaEye, FaEyeSlash, FaFacebookF, FaGooglePlusG, FaGoogle, FaLock, FaFacebook } from "react-icons/fa";
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { SignInManager } from '../../Auth/SignInManager';
import { setAuthSubmitting, setResponse, setShowModel } from '../../Store';
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import { AuthContext } from '../../Shared/AuthContext';
import { config } from '../../Environments';
import { useTranslation } from 'react-i18next';
import SocialButton from "./SocialButton";
import FacebookButton from "./Facebook";
const Index = () => {


    useEffect(() => {
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
    
    }, []);

    const handleSocialLogin = (user: any) => {
        console.log(user);
    };

    const handleSocialLoginFailure = (err: any) => {
        console.error(err);
    };



    const { t } = useTranslation('home');
    const { t: ct } = useTranslation('common');
    const { t: ts } = useTranslation('alert');
    const { handleSubmit, register } = useForm<LoginForm>({
        defaultValues: {
            username: '',
            password: ''
        }
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const auth = useSelector((state: AppStore) => state.auth);
    const handleClose = () => dispatch(setShowModel(false));

    const goForgot = () => {
        handleClose();
        navigate("/account/forgotpassword");
    };

    const submit = async (values: LoginForm) => {
        dispatch(setAuthSubmitting(true));
        const manager = new SignInManager();
        if (values.username == "admin@bonjanjob.com") {
            toast(ts('signIn.inValidUser'), { type: 'error', position: 'bottom-left' });
            navigate("/", { replace: true });
            dispatch(setAuthSubmitting(false));
        }
        else {
            try {

                const response = await manager.Login(values.username, values.password);
                if (response.data && response.data.access_token) {
                    manager.SetToken(response.data);

                    dispatch(setResponse(response.data));
                    dispatch(setAuthSubmitting(false));
                    handleClose();
                    //if admin try to login from here 
                    //then not login admin and logout admin 

                    toast(ts('signIn.loggedIn'), { type: 'success', position: 'top-right' });
                    navigate(localStorage.getItem("isPreviousPath") ?? "/", { replace: true });

                    localStorage.setItem("isPreviousPath", "/");

                }
            } catch (e) {
                toast(ts('signIn.inValidUser'), { type: 'error', position: 'bottom-left' });
                dispatch(setAuthSubmitting(false));
            }
        }

    };

    return (
        <>
            {
                !auth.isAuthenticated &&
                <Link to={''} onClick={e => dispatch(setShowModel(true))} className="fw-600 text-uppercase text-decoration-none fs-14">
                    <span className="text-white">
                        {t('loginPage.signInRegister')}
                    </span>
                </Link>
            }

            <Offcanvas show={auth.showModel} placement="end" onHide={handleClose}>
                <Offcanvas.Header closeButton />
                <Offcanvas.Body className='pt-0'>
                    <h3 className='fw-700'>{t('loginPage.welcome')}</h3>
                    <p>{t('loginPage.logingCredentailLine')}</p>
                    <div className='section-login'>
                        <form onSubmit={handleSubmit(submit)}>
                            <div className='login-form'>
                                <div className='mb-12'>
                                    <div className="input-group">
                                        <span className="input-group-text group-round" id="Email"><FaEnvelope /></span>
                                        <input type="text"
                                            className="form-control key-weight"
                                            placeholder={t('loginPage.email').toString()}
                                            {...register('username', { required: true })} />
                                    </div>
                                </div>
                                <div className="mb-12">
                                    <div className="input-group">
                                        <span className="input-group-text group-round" id="Password"><FaLock /></span>
                                        <input type={showPassword ? 'text' : "password"}
                                            className="form-control key-weight"
                                            placeholder={ct('password').toString()}
                                            {...register('password', { required: true })} />
                                        <span onClick={e => setShowPassword(!showPassword)} className="input-group-text group-round">
                                            {
                                                showPassword ? <FaEye /> :
                                                    <FaEyeSlash />
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-12">
                                    <div className="text-end">
                                        <span onClick={goForgot} className='text-decoration-none fw-600 fs-14 cursor-pointer color-text'>
                                            {ct('forgotPassword')}
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-12">
                                    <div className="text-center">
                                        <button type="submit"
                                            disabled={auth.isLoading}
                                            className="border-0 rounded btn-login text-white fw-600">
                                            {auth.isLoading ?
                                                <RotatingLines width='20' />
                                                : <div>{t('loginPage.signIn')}</div>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className='bottom-sec'>
                            <div className="first">
                                <span className="bar text-uppercase fw-600">{ct('or')}</span>
                            </div>
                            <div className='text-center pt-3'>
                                <h5 className='fw-600 mb-4'>{t('loginPage.withSocialNetwork')}</h5>
                            </div>


                            {/* <div className="d-flex align-items-center justify-content-center flexcolumnsm mb-3 desktop-social-links-register"> */}
                                <div className='d-flex  flexcolumnsm mb-3 desktop-social-links-register'>
                                <form action={config.apiBase + '/api/Auth/RemoveExistingAndGetReadyForNewLogin'} method={'post'}>
                                    <input type={'hidden'} name='provider' defaultValue={'Google'} />
                                    <button type="submit" className="me-2 rounded-0 rounded-pill border-2 login-btn">
                                        <span className="icon-social rounded-5 me-1"><FaGooglePlusG className='g-red' size={18} /></span>
                                        {t('loginPage.loginGoogle')}
                                    </button>
                                </form>
                                 {/* <SocialButton
                                    provider="facebook"
                                    appId="1300734100821893"
                                    onLoginSuccess={handleSocialLogin}
                                    onLoginFailure={handleSocialLoginFailure}
                                >
                                    {t('loginPage.loginFacebook')}
                                </SocialButton>  */}
                                 <form className='mt-10px-j02' action={config.apiBase + '/api/Auth/RemoveExistingAndGetReadyForNewLogin'} method={'post'}>
                                     <input type={'hidden'} name='provider' defaultValue={'Facebook'} /> 
                                    <button type="submit" className="rounded-0 rounded-pill login-btn">
                                        <span className="icon-social rounded-5 me-1"><FaFacebook className='f-blue' size={18} /></span>
                                        {t('loginPage.loginFacebook')}
                                    </button>
                                </form> 

                            </div>
                            <div className="d-flex align-items-center justify-content-center mb-3 mob-social-link-register">
                                <form action={config.apiBase + '/api/Auth/RemoveExistingAndGetReadyForNewLogin'} method={'post'}>
                                    <input type={'hidden'} name='provider' defaultValue={'Google'} />
                                    <button type="submit" className='default-btn-none'>
                                        <span className="me-2"><FaGooglePlusG className='g-red' size={30} /></span>
                                    </button>
                                </form>
                               

                                {/* <FacebookButton
                                    provider="facebook"
                                    appId="1300734100821893"
                                    onLoginSuccess={handleSocialLogin}
                                    onLoginFailure={handleSocialLoginFailure}
                                >
                                
                                </FacebookButton>  */}
                                  <form className='' action={config.apiBase + '/api/Auth/RemoveExistingAndGetReadyForNewLogin'} method={'post'}>
                                     <input type={'hidden'} name='provider' defaultValue={'Facebook'} /> 
                                    <button type="submit" className='default-btn-none'>
                                        <span className="me-1"><FaFacebook className='f-blue' size={30} /></span>
                                    </button>
                                </form>  
                            </div>
                            <div className='pt-3'>
                                <Card className="text-center">
                                    <Card.Body>
                                        <Card.Title className='fw-700'>{t('loginPage.newBonjanJob')}</Card.Title>
                                        <Card.Text className='mb-2 fw-500'>
                                            {t('loginPage.visibleTopRecruiters')} <span className='fw-bolder'>{ct('haiti')}</span>
                                        </Card.Text>
                                        <RegisterPopup onHide={() => {
                                            handleClose();
                                        }} />
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}
export default Index;


export const RegisterPopup = (props: {
    onHide: () => void
}) => {

    const { t } = useTranslation('home');
    const { t: ct } = useTranslation('common');

    const [register, SetRegister] = useState(false);

    const close = () => {
        SetRegister(false);
        props.onHide();
    }

    return (
        <>
            <div>
                <button type="submit" className="border-0 rounded btn-register text-white fw-600" onClick={s => SetRegister(true)}>{t('loginPage.registerUs')}</button>
                <Modal show={register}>
                    <Modal.Header closeButton onClick={() => SetRegister(false)}>
                        <Modal.Title>{t('loginPage.pleaseRegister')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-left">
                            <div className="mb-3">
                                <Link className='wel-color fs-20' onClick={close} to="/candidate/register">{ct('jobSeeker')}</Link>
                                <p className='cursor-no mb-0 fs-20'><b>or</b></p>
                                <Link className='wel-color fs-20' onClick={close} to="/company/registration">{ct('employer')}</Link>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => SetRegister(false)} className="rounded-0">
                            {ct('close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}