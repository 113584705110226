
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetAxios, UserRole } from '../../Shared';
import { setGlobalLoading } from '../../Store';
import { config } from '../../Environments';
import { useCallback, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Index = () => {

    const { t: ct } = useTranslation('common');
    const { t: at } = useTranslation('auth');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('footer');

    const [data, setData] = useState<ProfileView>();
    const auth = useSelector((state: AppStore) => state.auth);
    const userRole = UserRole(auth?.raw?.access_token);
    useEffect(() => {
        if (data === undefined) {
            dispatch(setGlobalLoading(true));
            GetAxios().get(config.apiBase + '/api/General/AccountProfileView/').then(res => {
                setData(res.data.data);
                dispatch(setGlobalLoading(false));
            });
        }
    }, [data]);

    const onCurrencyChange = (e: any) => {
        setData({ ...data, currency: e.target.value as string } as ProfileView);
        GetAxios().patch(config.apiBase + '/api/General/SaveCurrency?currency=' + e.target.value).then(res => {
            if (!res.data.success) {
                setData({ ...data, currency: "USD" } as ProfileView)
            }
        }).catch(err => {
            console.error(err);
        });
    }


    return (
        <>
            <div className='container'>
                <h2 className='fw-700 my-4'> {ct('myProfile')} </h2>
                <div className='card p-4'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='fs-14'>
                                <p className='text-uppercase fw-600 mb-0 text-gray'> {at('accountSetting.fullName')} </p>
                                <p className='fw-600 fsize-13 mt-1'>{data?.firstName} {data?.lastName}</p>
                            </div>
                            {userRole == 'Employer' &&
                                <>
                                    <div className='fs-14'>
                                        <p className='text-uppercase fw-600 mb-0 text-gray'> {at('accountSetting.organization')} </p>
                                        <p className='fw-600 fsize-13 mt-1'>{data?.companyName || 'N/A'}</p>
                                    </div>
                                </>
                            }
                            {userRole == 'Employee' &&
                                <div className='fs-14'>
                                    <p className='text-uppercase fw-600 mb-0 text-gray'> {ct('email')}</p>

                                    <p className='fw-600 fsize-13 mt-1'>{data?.email || 'N/A'} </p>
                                </div>
                            }


                        </div>
                        <div className='col-md-3'>
                            <div className='fs-14'>
                                <p className='text-uppercase fw-600 mb-0 text-gray'> {at('accountSetting.mobilePhone')} </p>
                                <p className='fw-600 fsize-13 mt-1' >{data?.phoneNumber || 'N/A'}</p>
                            </div>
                            <div className='fs-14'>
                                <p className='text-uppercase fw-600 mb-0 text-gray'> {at('accountSetting.primaryPhone')} </p>
                                <p className='fw-600 fsize-13 mt-1'>{data?.phoneNumber || 'N/A'}</p>
                            </div>
                        </div>
                        {userRole == 'Employer' &&
                            <div className='col-md-6'>
                                <div className='fs-14'>
                                    <div className='d-flex justify-content-between'>
                                        <p className='text-uppercase fw-600 mb-0 text-gray'> {at('accountSetting.address')} </p>
                                        <span className='fw-600'><Link to="/company/editcompanyprofile" className='text-decoration-none'> {ct('edit')} </Link></span>
                                    </div>
                                    <p className='fw-600 fsize-13 mt-1' dangerouslySetInnerHTML={{ __html: data?.address || "N/A" }}></p>
                                </div>
                                <div className='fs-14'>
                                    <div className='d-flex justify-content-between'>
                                        <p className='text-uppercase fw-600 mb-0 text-gray'> {ct('email')} </p>
                                        <p className='text-uppercase fw-600 mb-0 text-gray'> {t('currencyChoice')} </p>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className='fw-600 fsize-13 mt-1'>{data?.email || 'N/A'}</p>
                                        <div className="ms-1">
                                            <select name="currencyofchoice" value={data?.currency} onChange={onCurrencyChange} className='form-select fw-12 form-select-sm fw-600'>
                                                <option key={"c_" + 1} value="USD">USD</option>
                                                <option key={"c_" + 2} value="HTG">HTG</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {userRole == 'Employee' &&
                            <div className='col-md-3'>
                                <div className='fs-14'>
                                    <p className='text-uppercase fw-600 mb-0 text-gray'> {at('accountSetting.availability')}</p>
                                    <p className='fw-600 fsize-13 mt-1'>{data?.availability || 'N/A'}</p>
                                </div>
                                <div className='fs-14'>
                                    <p className='text-uppercase fw-600 mb-0 text-gray'>{ct('maritalStatus')} </p>
                                    <p className='fw-600 fsize-13 mt-1'>{data?.maritalStatus || 'N/A'}</p>
                                </div>
                            </div>
                        }
                        {userRole == 'Employee' &&
                            <div className='col-md-3'>
                                <div className='fs-14'>
                                    <div className='d-flex justify-content-between'>

                                        <p className='text-uppercase fw-600 mb-0 text-gray'>{at('accountSetting.nationality')}  </p>
                                        <span className='fw-600'><Link to="/candidate/editcandidateprofile" className='text-decoration-none'> {ct('edit')} </Link></span>

                                    </div>
                                    <p className='fw-600 fsize-13 mt-1'>{data?.nationality || 'N/A'}</p>
                                </div>
                                <div className='fs-14'>
                                    <div className='d-flex justify-content-between'>
                                        <p className='text-uppercase fw-600 mb-0 text-gray'> {ct('city')} </p>
                                        <p className="text-uppercase fw-600 mb-0 text-gray me-1">{t('currency')}</p>

                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <p className='fw-600 fsize-13 mt-1' >{data?.address || 'N/A'}</p>
                                        <div className="ms-1">
                                            <select name="currencyofchoice2" value={data?.currency} onChange={onCurrencyChange} className='form-select fw-12 form-select-sm fw-600'>
                                                <option key={"c_" + 3} value="USD">USD</option>
                                                <option key={"c_" + 4} value="HTG">HTG</option>
                                            </select>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className='profile-card my-4'>
                    <div className='row gx-3'>
                        <div className='col-md-4 mb-3'>
                            <div className='card p-4'>
                                {userRole == 'Employer' &&
                                    <div className='fs-14 text-center' onClick={() => { navigate("/employer-account/billings") }}>
                                        <img src="/images/billing-icon.png" alt="Billing Icon" className='icon-phone' />
                                        <p className='fw-600 fs-20'> {at('accountSetting.billing')} </p>
                                        <p className='fw-600 text-gray mb-0'> {at('accountSetting.expiringProductBillingDates')} </p>
                                    </div>
                                }
                                {userRole == 'Employee' &&
                                    <div className='fs-14 text-center'>
                                        <img src="/images/billing-icon.png" alt="Billing Icon" className='icon-phone' />
                                        <p className='fw-600 fs-20'> {at('accountSetting.billing')} </p>
                                        <p className='fw-600 text-gray mb-0'> {at('accountSetting.expiringProductBillingDates')} </p>
                                    </div>
                                }

                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='card p-4'>
                                {userRole == 'Employer' &&
                                    <div className='fs-14 text-center' onClick={() => { navigate("/employer-account/payment-methods") }}>
                                        <img src="/images/payment-card.png" alt="Payment card" className='icon-phone' />
                                        <p className='fw-600 fs-20'> {at('accountSetting.paymentMethods')} </p>
                                        <p className='fw-600 text-gray mb-0'> {at('accountSetting.creditMoncashPayPal')} </p>
                                    </div>
                                }
                                 {userRole == 'Employee' &&
                                    <div className='fs-14 text-center'>
                                        <img src="/images/payment-card.png" alt="Payment card" className='icon-phone' />
                                        <p className='fw-600 fs-20'> {at('accountSetting.paymentMethods')} </p>
                                        <p className='fw-600 text-gray mb-0'> {at('accountSetting.creditMoncashPayPal')} </p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='col-md-4 mb-3'>
                            <div className='card p-4'>
                                <div className='fs-14 text-center'>
                                    <img src="/images/order-history.png" alt="Order history" className='icon-phone' />
                                    <p className='fw-600 fs-20'> {ct('orderHistory')} </p>
                                    <p className='fw-600 text-gray'> {at('accountSetting.pastPendingOrders')} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Index;

export interface ProfileView {

    firstName: string,
    lastName: string,
    companyName: string,
    email: string,
    phoneNumber: string,
    address: string,
    currency: string,
    nationality: string,
    maritalStatus: string,
    availability: string,
    lang: string,
    gender: string,

}
{/* <div className='fs-14'>
<div className='d-flex align-items-center justify-content-between'>
    <p className='text-uppercase fw-600 mb-0 text-gray'> {ct('email')}</p>
    <div className='d-flex align-items-center'>
        <p className="text-uppercase fw-600 mb-0 text-gray me-2">{t('currencyChoice')}</p>
        <div className="ms-1">
            <select name="currencyofchoice" value={data?.currency} onChange={onCurrencyChange} className='form-select fw-12 form-select-sm fw-600'>
                <option key={"c_" + 1} value="USD">USD</option>
                <option key={"c_" + 2} value="HTG">HTG</option>
            </select>
        </div>
    </div>
</div>
<p className='fw-600 fsize-13'>{data?.email || 'N/A'}</p>
</div> */}