import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SignInManager } from "../Auth/SignInManager";

export const LoginCallBack = () => {
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    const [message, setMessage] = React.useState('Loading...');
    const [errorState, setErrorState] = React.useState(false);
   
    React.useEffect(() => {
        if (queryParams.has('error') && queryParams.get('error')) {
            setErrorState(true);
            setMessage(queryParams.get('error') || 'Error');
        } else {
            setMessage('Redirecting...');
            var signInManager = new SignInManager();
            signInManager.SetToken({
                access_token: queryParams.get('access_token') || '',
                expires_in: Number(queryParams.get('expires_in') || 0),
                token_type: queryParams.get('token_type') || '',
                id_token: queryParams.get('id_token') || '',
                refresh_token: queryParams.get('refresh_token') || ''
            });
           
            navigate('/', { replace: true });
        }
    }, [queryParams]);
    return (
        <div>
            {
                errorState ?
                    <>
                        <p className="text-center text-danger">{message}</p>
                        <Link to='/' replace className="btn btn-primary">Go home</Link>
                    </>
                    : <>
                        <p className="">{message}</p>
                    </>
            }
        </div>
    )
}